import {Component, OnInit, ViewChild} from '@angular/core';
import {StockService} from "./service/stock.service";
import {MatDatepickerInputEvent, MatSort, MatTableDataSource} from "@angular/material";
import {FormControl} from "@angular/forms";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  @ViewChild(MatSort) sort: MatSort;

  date = new FormControl(new Date());

  private stocks;

  public dataSource;

  public currentDate;

  displayedColumns: string[] = ["index", 'name', 'code', 'price', 'book', 'priceToBook', 'earnings', 'priceToEarnings', 'pz'];

  constructor(private stockService: StockService) {

    var today = new Date();

    const format = 'dd-MM-yyyy';
    const locale = 'pl-PL';
    const formattedDate = formatDate(today, format, locale);

    this.currentDate = formattedDate;

    this.stockService.getStocks(formattedDate).subscribe(
      response => {
        if (response == null) {
          this.stocks = [];
        } else {
          this.stocks = response;
        }

        this.dataSource = new MatTableDataSource(this.stocks);
        this.dataSource.sort = this.sort;
      }
    );
  }

  ngOnInit(): void {

  }

  changeDate(event: MatDatepickerInputEvent<Date>) {
    const format = 'dd-MM-yyyy';
    const locale = 'pl-PL';
    const formattedDate = formatDate(event.value, format, locale);

    this.currentDate = formattedDate;

    this.stockService.getStocks(formattedDate).subscribe(
      response => {
        if (response == null) {
          this.stocks = [];
        } else {
          this.stocks = response;
        }

        this.dataSource = new MatTableDataSource(this.stocks);
        this.dataSource.sort = this.sort;
      }
    );

  }
}
